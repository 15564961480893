<div class="container_cy !p-0 !m-0">
    <div class="w-full ">
        <div class="w-full bg-white self-start mx-auto">
            <div class="sm:px-8 px-4 py-8 w-full mx-auto">
                <div class="h-4 w-full"></div>
                    <h5 class="font__title font-semibold text-lg text-primary text-center">Total Recaudado: <strong class="text-2xl">Bs. {{totalAmount}}</strong></h5>
            </div>
        </div>
    </div>
</div>
