import { Routes } from '@angular/router';
import { routes as mainRoutes } from '../../../../src/app/main.routes';
import { environment } from '../environments/environment';
import { ContributionClubPage } from '../../../../src/app/pages/contribution-club/contribution-club.page';
import { PaymentClubPage } from '../../../../src/app/pages/payment-club/payment-club.page';
import { IfremePage } from '../../../../src/app/pages/ifreme/ifreme.page';

export const routes: Routes = [
    {
        path: '',
        redirectTo: environment.authRedirect,
        pathMatch: 'full',
    },
    {
      path: 'mi-aporte',
      component: ContributionClubPage,
    },
    {
      path: 'iframe',
      component: IfremePage,
    },
    ...mainRoutes
];