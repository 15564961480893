<div class="container_cy">
    <div class="w-full my-10">

        @if(env.type === 'oriente-petrolero'){
            <img [src]="env.imgLogoBanner" alt="oriente-petrolero" class="mb-8 w-full">
        }
        
        
        <!-- <div class="w-full bg-white mb-2">
            <profile-settings [column]="false" />
        </div> -->
        <div class="w-full bg-white self-start max-w-[800px] mx-auto">
            <div class="sm:px-8 px-4 py-8 w-full max-w-[600px] mx-auto">
               <!--  <img [src]="env.imgLogoBig" [alt]="env.title" class="mx-auto w-[150px]"> -->
    <!--             <h5 class="font__title font-semibold text-lg uppercase text-primary">{{ title }}</h5> -->
                <div class="h-4 w-full"></div>

                    <h5 class="font__title font-semibold text-lg text-primary text-center">Portal de aportes Voluntarios</h5>
                    <h5 class="font__title font-semibold text-lg text-primary text-center">Aporte a la fecha: <strong class="text-2xl">Bs. {{totalAmount}}</strong></h5>
                    <h5 class="font__title font-semibold text-lg mt-4">Llena tus datos personales por favor.</h5>

                <form (ngSubmit)="submit()" [formGroup]="dataForm">
                    <div class="input_group flex flex-wrap gap-4 items-center">
                        <label [class]="'!w-[150px] inline-block'" for="first_name">Nombre <span class="text-red-500">*</span></label>
                        <input class="flex-1 min-w-[250px] !border-gray-300" id="name" type="text" name="first_name"  formControlName="first_name" placeholder="Ingresa tu nombre" />
                    </div>
                    <div class="input_group flex flex-wrap gap-4 items-center">
                        <label [class]="'!w-[155px] inline-block'" for="last_name" class="">Apellido<span class="text-red-500">*</span></label>
                        <input class="flex-1 min-w-[250px] !border-gray-300" id="name" type="text" name="last_name" formControlName="last_name" placeholder="Ingresa tu apellido" />
                    </div>
                    <div class="input_group flex flex-wrap gap-4 items-center">
                        <label [class]="'!w-[155px] inline-block'"  for="email" class="">Correo electrónico<span class="text-red-500">*</span></label>
                        <input class="flex-1 min-w-[250px] !border-gray-300" id="email" type="text" name="email" formControlName="email" placeholder="Ingresa tu correo" />
                    </div>
                    <div class="input_group flex flex-wrap gap-4 items-center">
                        <label [class]="'!w-[155px] inline-block'"  for="cellphone" class="">Teléfono</label>
                        <input class="flex-1 min-w-[250px] !border-gray-300" id="cellphone" type="cellphone" name="cellphone" formControlName="cellphone" placeholder="Ej: 72451200" />
                    </div>
                    <div class="input_group w-full !pb-0">
                        <h4 class="font__title font-semibold text-gray-400">Si no deseas colocar tu nombre llena la casilla y tu registro se guardará como Anónimo:</h4>
                    </div>
    
                    <div class="input_group flex gap-4 items-center justify-center">
                        <input class=" !w-[30px] h-[30px] text-secondary !border-gray-300" id="hidden_name" type="checkbox" name="hidden_name"  formControlName="hidden_name" >
                        <label  [class]="'w-fit'" for="hidden_name" class="">Ocultar mi nombre en la lista de aportes</label>
                    </div>

                    <br>
                    <div class="input_group flex flex-wrap gap-4 items-center justify-center">
                        <label [class]="'!w-full text-center !text-xl font__title !border-gray-600'"  for="amount" class="">Monto de su aporte en Bolivianos<span class="text-red-500">*</span></label>
                        <input class="flex-1 min-w-[250px] max-w-[300px] !text-[25px] text-center " id="amount" type="number" name="amount" formControlName="amount" placeholder="0" />
                    </div>

                    <div class="input_group flex flex-wrap gap-4 items-center justify-center">
                        <button-cy type="submit" [disabled]="dataForm.invalid" addClass="w-full btn__primary" label="Pagar" />
                    </div>

                </form>

                
            </div>
        </div>

        <div class="w-full bg-white self-start mt-4 p-5 max-w-[800px] mx-auto">
            <div class="max-w-[350px] mx-auto">
                <h5 class="font__title font-semibold text-primary text-center text-2xl">Últimos Aportes</h5>
    
                <datatable-capyei [dataSource]="dataSource" [columnsdef]="columnsSubs" [hasSearch]="false" [loadingData]="loadingData" [noData]="noData" [customTemplate]="customTemplate" [hasPagination]="false">
                    <ng-template #customTemplate let-item let-row="row" let-column="column" let-i="index">
                        <ng-container [ngSwitch]="column.id">
                            <ng-container *ngSwitchCase="'user_donation'">
                                <span class="uppercase" >{{item.first_name}} {{item.last_name}}</span>                        
                            </ng-container>
                            <ng-container *ngSwitchCase="'amount'">
                                <span class="uppercase" >{{item}} Bs</span>                        
                            </ng-container>    
                            <ng-container *ngSwitchDefault>
                                <span class="two_line-hidden">{{ item ?? '-' }}</span>
                            </ng-container>
                        </ng-container>
                    </ng-template>

                </datatable-capyei>
            </div>


            <!-- <div class="w-full bg-white rounded-2xl shadow py-8 md:px-10 px-5 mt-6">
                <div class="w-auto max-w-full grid grid-cols-12 gap-2" *ngIf="pagination">
            
                    <div class="col-span-12 flex items-center justify-center md:justify-center">
                        <ul class="pagination_table-out">
                            <ng-container *ngFor="let pag of pagination['links']; let first = first; let last = last">
                                <ng-container *ngIf="first||last; else elsePag">
                                    <ng-container *ngIf="first;">
                                        <li class="paginate_button page-item previous">
                                            <a href="javascript:void(0);"
                                                (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link"
                                                [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                                                <i class="fa-regular fa-left-long"></i>
                                            </a>
                                        </li>
                                    </ng-container>
                                    <ng-container *ngIf="last;">
                                        <li class="paginate_button page-item next">
                                            <a href="javascript:void(0);"
                                                (click)="goToPaginate((pag['active'] ? null : pag['url']))" class="page-link"
                                                [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                                                <i class="fa-regular fa-right-long"></i>
                                            </a>
                                        </li>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elsePag>
                                    <li class="paginate_button page-item">
                                        <a href="javascript:void(0);" (click)="goToPaginate((pag['active'] ? null : pag['url']))"
                                            class="page-link" [ngClass]="{'active': pag['active'], 'disabled': !pag['url']}">
                                            {{ pag['label'] }}
                                        </a>
                                    </li>
                                </ng-template>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="col-span-12 w-full flex items-center justify-center md:justify-center">
                        <div class="w-auto text-sm">
                            {{ (pagination['to'] ? pagination['to'] : '0') +' de '+ pagination['total'] }} Elementos
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
</div>
